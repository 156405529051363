import React from "react";
import { Menu } from "../../headers/menu";
import { Modal } from "../../modal";
import { Viz } from "../../visualisations";
import { CountryReportMixer as Mixer } from "../../mixer";
import defaultClass from "./css/ContainerLayout.module.css";
import classes from "./css/CountryReport.module.css";

export const Image = (props) => {
  const { data, bg, children, selectedMenu, match, toggleMenu } = props;
  const { header, subHeader, menu } = data;
  const headerWrapperclass = [defaultClass["Header"], "container"].join(" ");
  const backgroundImage = bg && (
    <div className={defaultClass.Hero__image}>
      <img src={bg} alt={header} />
    </div>
  );
  const subMenu = menu && (
    <Menu
      list={menu}
      match={match.url}
      selectedMenu={selectedMenu}
      toggleMenu={toggleMenu}
    />
  );
  return (
    <main>
      <section className={bg ? defaultClass.Hero : defaultClass.HeroText}>
        <div className={defaultClass.Hero__inner}>
          {backgroundImage}
          <div className={headerWrapperclass}>
            <header className={defaultClass["Header__inner"]}>
              <h1>{header}</h1>
              <h4>{subHeader}</h4>
            </header>
          </div>
        </div>
      </section>
      {subMenu}
      {children}
    </main>
  );
};

export const Text = ({ data, selectedMenu, border, ...props }) => {
  const [open, setOpen] = React.useState(false);
  let borderStyle = border
    ? [defaultClass.Text_TitleWrapper, defaultClass.border].join(" ")
    : defaultClass.Text_TitleWrapper;
  let style = open ? [borderStyle, defaultClass.expand].join(" ") : borderStyle;
  return (
    <main>
      <section id="test" className={style}>
        <div
          className={[defaultClass.Text_TitleContainer, "container"].join(" ")}
        >
          <header className={defaultClass.Text_HeaderContent}>
            <h1>{data.header}</h1>
            {data.subHeader.type === "modalText" ? (
              <Modal.Text
                data={data.subHeader}
                toggleButton={() => setOpen(!open)}
                open={open}
              />
            ) : (
              <h4>{data.subHeader.title || data.subHeader}</h4>
            )}
          </header>
        </div>
      </section>
      {data.menu && (
        <Menu
          list={data.menu}
          match={props.match.url}
          selectedMenu={selectedMenu}
          toggleMenu={props.toggleMenu}
        />
      )}
      {props.children}
    </main>
  );
};

export const CountryReport = ({
  selectedCountry,
  children,
  config,
  ...props
}) => {
  return (
    <>
      <section className={classes.HeaderWrapper}>
        <Viz.CountryReportMap
          styles={classes.MapWrapper}
          selectedCountry={selectedCountry}
          history={props.history}
          country={props.match.params.country}
          locale={props.match.params.locale}
        />
        <Mixer {...props} data={selectedCountry} config={config} />
      </section>
      {children}
    </>
  );
};
